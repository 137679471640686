/*================================================================================
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "@styles/base/bootstrap-extended/include"; // Bootstrap includes
@import "@styles/base/components/include"; // Components includes
@import "@styles/react/apps/app-email.scss";
// ** React loading skelton styles
@import "react-loading-skeleton/dist/skeleton.css";

.table-btn {
  background-color: transparent !important;
  border: none !important;
  padding: 0.5rem 0.7rem;
  &:hover,
  &:focus {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.table-dropdown {
  .dropdown-item {
    width: 100%;
  }
  button.dropdown-item {
    svg {
      margin-top: -0.25rem;
    }
  }
}

.modal .modal-header {
  align-items: center;
}

.modal .modal-header .close {
  transform: translate(0, 0);
  box-shadow: none;
  background: transparent;
  margin: 0;
  &:hover {
    transform: translate(0, 0);
  }
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
}

a.brand-logo {
  width: -moz-fit-content;
  width: fit-content !important;
  img {
    height: 4rem !important;
  }
}

span.brand-logo.light {
  display: flex;
}
span.brand-logo.dark {
  display: none;
}

// .avatar-photo {
// 	width: 100px;
// 	height: 100px;
// }

.dark-layout {
  [role="table"] {
    .rdt_TableHeadRow {
      background-color: #171e31;
      color: #fff;
    }
    .rdt_TableRow {
      background-color: #171e31;
      color: #fff;
    }

    .table-btn {
      svg {
        stroke: #6e6b7b !important;
      }
    }
  }

  .react-select > div {
    background-color: #283046 !important;
    border-color: #404656 !important;
    .select__option--is-focused,
    .select__option--is-selected {
      background-color: #171e31 !important;
    }
    .select__single-value {
      color: #b4b7bd !important;
    }
  }

  .select__control .select__multi-value {
    background-color: #161d31 !important;

    .select__multi-value__label {
      color: white !important;
    }
  }

  .uppy-FileInput-btn {
    color: #b4b7bd !important;
    border-color: #b4b7bd !important;
    &:hover {
      background-color: #171e31 !important;
    }
  }

  input:-internal-autofill-selected {
    background-color: #283046 !important;
  }

  .select__control {
    .select__input {
      color: #ddd !important;
    }
  }

  .rdt_Table {
    > div {
      background-color: #171e31 !important;
      color: #ddd;
    }
  }

  .modal-body .d-flex.justify-content-end {
    border-top: 1px solid #171e31;
  }

  .select__placeholder {
    color: $body-color !important;
    opacity: 1;
    font-weight: 400;
  }

  .select__single-value {
    color: $body-color !important;
  }

  .ant-tooltip-inner,
  .ant-tooltip-arrow-content {
    background-color: #171e31 !important;
  }

  span.brand-logo.light {
    display: none;
  }
  span.brand-logo.dark {
    display: flex;
  }

  .logo-light {
    display: none;
  }

  .logo-dark {
    display: inline-block;
  }

  .select__control.select__control--is-disabled {
    opacity: 0.5 !important;
  }

  .disabled.form-group {
    background-color: transparent;
    opacity: 1;
    input.form-control:not(:focus),
    .custom-file-label:not(:focus),
    textarea.form-control:not(:focus) {
      color: #b4b7bd;
      opacity: 0.5 !important;
    }
    .input-group-text {
      background-color: transparent;
      color: #b4b7bd;
      opacity: 0.5;
    }
  }

  .disabled.form-group .input-group-merge .form-control:not(:first-child) {
    background-color: transparent !important;
    border-color: #404656 !important;
  }

  .reservation__data-card {
    background-color: #111729 !important;
  }

  .ui-loader.show .overlay {
    background-color: #161d31cc;
  }

  input:-webkit-autofill:disabled,
  textarea:-webkit-autofill:disabled,
  select:-webkit-autofill:disabled {
    -webkit-box-shadow: 0 0 0 30px #283046 inset !important;
    box-shadow: 0 0 0 30px #283046 inset !important;
  }

  .code-input {
    background-color: #161d31 !important;
    color: white !important;
  }

  .user-profile__tabs {
    .nav-item {
      .nav-link {
        border-bottom-color: #11d8df1a !important;
      }
      .nav-link.active {
        border-bottom-color: $primary !important;
      }
    }
  }
} // dark-layout end

.upload-card {
  margin-bottom: 0;
  .card-body {
    padding: 1rem;
  }
}

.filter-input {
  display: flex;
  flex: 1;
  .react-select {
    width: 100%;
  }
}

.filter-group {
  label {
    margin: 0 1rem;
  }
}

.rdt_TableCol {
  text-transform: capitalize;
}

.card-header,
.modal-header {
  h5,
  .card-title {
    text-transform: capitalize;
  }
}

.modal-footer {
  button {
    text-transform: capitalize;
  }
}

.form-group {
  label {
    text-transform: capitalize;
  }
}

.nav-item {
  a {
    text-transform: capitalize;
  }
}

.breadcrumb-item,
.content-header-title {
  text-transform: capitalize;
}

@media (max-width: 648px) {
  .content-header .breadcrumb {
    display: flex !important;
    padding-left: 0 !important;
  }
}

@media (max-width: 767px) {
  .breadcrumb-right > div {
    justify-content: flex-start !important;
  }
}

.table-loading {
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  justify-content: center;
  align-items: center;
  .spinner-grow {
    margin-bottom: 2rem;
  }
}

.modal-body .d-flex.justify-content-end {
  border-top: 1px solid #eee;
}

.select__placeholder {
  color: $body-color !important;
  opacity: 0.6;
  font-weight: 400;
}

.select__single-value {
  color: $body-color !important;
}

.btn-small {
  padding: 0.5rem 0.5rem;
  width: auto;
  display: inline-block;
  max-width: initial;
  width: 40px;
  &.update-btn {
    width: 75px;
  }
}

.d-flex.justifu-content-end.col-sm-3 {
  display: flex;
  justify-content: center;
  .btn-small {
    margin-bottom: 1rem;
    margin-top: 2rem;
    height: 38px;
  }
}

.instalments {
  .btn-small {
    margin-top: 0;
  }
}

.det-row {
  padding: 0.5rem 0;
}

.btn-transparent {
  padding: 0;
  color: $secondary !important;
}

.form-control[readonly] {
  background-color: initial;
  opacity: 1;
}

.table-btn {
  svg {
    stroke: #6e6b7b;
  }
}

.filter-form-group {
  label {
    margin: 0 -1rem;
  }
}

.table-loading {
  color: $body-color;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  height: 40px !important;
}

.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 1px;
}

.modal .modal-header {
  .btn-small {
    padding: 0.2rem;
  }
}

.mb-0 {
  margin-bottom: 0 !important;
}

span.input-group-text {
  .btn-small {
    background-color: transparent !important;
    padding: 0;
    border: none;
    &:hover {
      background-color: transparent !important;
    }
    svg {
      stroke: #6e6b7b;
    }
  }
}
// .rdt_TableCol_Sortable {
// 	.lnndaO {
// 		overflow: visible !important;
// 		white-space: break-spaces !important;
// 		text-overflow: initial !important;
// 	}
// }
.select__placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rdt_TableCell {
  > span {
    overflow: clip !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
}

.d-flex.p-1.justify-content-end {
  .spinner-border-sm {
    margin: 0 0.5rem;
  }
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

a.navbar-brand.active {
  padding: 0.5rem;
}

@media (max-width: 767px) {
  a.brand-logo {
    position: relative !important;
    margin-bottom: 4rem !important;
  }
}

a.navbar-brand.active .brand-logo {
  width: 100%;
  flex: 1;
}

a.navbar-brand.active .brand-logo img {
  height: 40px;
  transition: all 0.4s;
}

.main-menu .navbar-header .navbar-brand .brand-logo img {
  max-width: 100%;
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
  a.navbar-brand.active .brand-logo {
    margin: 0.5rem -0.5rem;
    img {
      width: auto !important;
      height: 15px !important;
      max-width: 100% !important;
    }
  }
}

.main-menu.menu-fixed.menu-accordion.menu-shadow.expanded {
  a.navbar-brand.active .brand-logo {
    margin: 0.5rem -0.5rem;
    img {
      width: auto !important;
      height: 40px !important;
      max-width: 100% !important;
    }
  }
}

.main-menu .navbar-header .navbar-brand {
  margin-top: 0;
}

@media (max-width: 1200px) {
  .navbar-header {
    margin-bottom: 1rem;
  }
}

.file-delete-btn {
  padding: 0.5rem 1rem;
  display: flex;
}

.rt-td {
  white-space: normal !important;
}

.gPyjSA div:first-child {
  white-space: normal !important;
}

button.waves-effect.btn-small.update-btn.btn.btn-primary,
button.waves-effect.btn-small.btn.btn-danger {
  width: auto;
}

label {
  color: #000;
}

li.breadcrumb-item a {
  color: $primary !important;
}

.content-wrapper > .card {
  margin-bottom: 0.2rem;
}

.uppy-FileInput-btn {
  color: $primary !important;
  border-color: $primary !important;
  &:hover {
    color: #fff !important;
    background-color: $primary !important;
  }
}

.logo-light {
  display: inline-block;
}

.logo-dark {
  display: none;
}

.disabled.form-group {
  .input-group-merge .form-control:not(:first-child) {
    background-color: #efefef !important;
    opacity: 1;
    border-color: #d8d6de !important;
  }
  .input-group-text {
    background-color: #efefef;
  }
}

.react-select {
  border-radius: 0.357rem;
}

.react-select.is-invalid {
  .select__control {
    border: 1px solid $danger !important;
  }
}

.is-invalid.date-picker.form-group {
  .input-group-text {
    border-top: 1px solid $danger;
    border-left: 1px solid $danger;
    border-bottom: 1px solid $danger;
    border-right: 1px solid transparent !important;
  }
}

.datePicker-disabled {
  background-color: #efefef !important;
}
.dark-layout .datePicker-disabled {
  background-color: #283046 !important;
}

.margin-top-half {
  margin-top: 0.5rem;
}

.secondary-color {
  color: $secondary !important;
}

.card-action.card .card-body {
  max-height: 600px;
  overflow-y: auto;
}

// .ui-loader {
// 	display: flex;
// 	flex: 1;
// 	.card-action.card {
// 		display: flex;
// 		flex: 1;
// 		background-color: transparent;
// 	}
// }

.no-shadow {
  box-shadow: none !important;
}
html .content.app-content {
  min-height: 94vh;
}

.stats-row {
  margin-bottom: 1rem;
  .stat {
    p {
      margin: 0;
    }
  }
}

.invalid-feedback:first-letter {
  text-transform: capitalize;
}

.select__menu {
  z-index: 999 !important;
}

.uppy-FileInput-container {
  margin: 0 !important;
}

.media-body > a {
  margin-top: 0;
}

.d-flex.align-items-end {
  margin-bottom: 1rem;
}

.reset-filters-btn {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: transparent !important;
  border-color: $danger !important;
  color: $danger !important;
  &:hover,
  &:focus {
    background-color: $danger !important;
    color: $white !important;
    box-shadow: none !important;
  }
}

.reset-filters-btn svg {
  margin-right: 7px;
}

@media (max-width: 767px) {
  .filter-btn-container {
    width: -moz-fit-content;
    width: fit-content;
  }
}

.avatar-image {
  img {
    width: 100%;
  }
}

.info-card {
  h6 {
    text-transform: capitalize;
  }
}

.btn-link {
  background-color: transparent !important;
  border: none !important;
  color: #b4b7bd !important;
  padding: 1rem 0.5rem !important;
  &:hover,
  &:focus {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.title-wrapper {
  margin: 1rem 0;
  padding: 1rem 0;
  border-bottom: 1px solid $border-color;
}

.danger-row {
  td:not(button span) {
    color: $danger;
  }
}

.danger-color {
  color: $danger !important;
}

.dropdown-menu .dropdown-item a {
  color: #6e6b7b;
  transition: none;
}
.dark-layout .dropdown-menu .dropdown-item a {
  color: #b4b7bd;
  transition: none;
}
.dropdown-menu .dropdown-item:hover a {
  color: $primary;
}

.refresh-btn {
  display: flex;
  align-items: center;
  background-color: transparent !important;
  border-color: $primary !important;
  color: $primary !important;
  &:hover,
  &:focus {
    background-color: $primary !important;
    color: $white !important;
    box-shadow: none !important;
  }
}

.refresh-btn svg {
  margin-right: 9px;
}

.btn-choose-files {
  min-width: 8rem;
}

@page {
  size: A3;
  margin: 10mm 10mm 10mm 10mm;
}

@media print {
  .main-menu {
    display: none !important;
  }
  .brand-logo {
    display: none !important;
  }
  html .content {
    margin: 0;
  }

  .hidden-print {
    display: none !important;
  }
  .full-width-print {
    width: 100% !important;
    max-width: 100% !important;
    min-width: 100% !important;
  }

  html .content.app-content {
    padding: 0 !important;
  }
  .navbar-floating .header-navbar-shadow {
    display: none !important;
  }

  .header-navbar {
    display: none !important;
  }

  footer {
    display: none !important;
  }

  .invoice-preview-card {
    box-shadow: 0 0 !important;
  }

  thead {
    page-break-after: always !important;
    display: table-header-group !important;
  }
}

.scroll-to-top div {
  bottom: 5% !important;
}

.knowledge-base-bg {
  background-image: url("../images/banner/banner.png");
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

.small-badge {
  min-width: 1.2rem !important;
  min-height: 1.2rem !important;
  top: -8px !important;
  right: -9px !important;
}

.overflow-visible {
  overflow: visible !important;
}

.disabled-input-icon-bg {
  background-color: #efefef;
}

.horizontal-menu
  .header-navbar.navbar-horizontal
  ul#main-menu-navigation
  > li
  > a {
  padding: 0.715rem 1rem !important;
}

html [data-col="1-column"].horizontal-layout .app-content {
  padding: 6rem 2rem 0 2rem !important;
}

.header-navbar.navbar-shadow {
  box-shadow: 0 2px 5px 0 rgba(34, 41, 47, 0.1) !important;
}

.horizontal-layout.navbar-floating .header-navbar-shadow {
  height: 2px !important;
}

.horizontal-layout .brand-logo img {
  max-width: 110px !important;
  margin-left: -11px;
}

.vertical-layout .brand-logo img {
  max-width: 125px !important;
  margin-top: 10px;
}

.statistics-body.card-body {
  padding-top: 0 !important;
}

.skeleton {
  width: 100%;
  margin-top: 10px;
}

.skeletonContainer {
  flex-grow: 1;
}

.dropzone {
  min-height: 200px !important;
}

svg:focus {
  outline: 0 !important;
}

.bg-gradient-overlay {
  background: $gradient-overlay;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

/* fix autocomplete styles in WebKit on disabled state */
input:-webkit-autofill:disabled,
textarea:-webkit-autofill:disabled,
select:-webkit-autofill:disabled {
  -webkit-box-shadow: 0 0 0 30px #efefef inset !important;
  box-shadow: 0 0 0 30px #efefef inset !important;
}

.roles__permission-label {
  width: 60px;
}

.fc-daygrid-day-events {
  .calendar__event-title {
    display: none;
  }
}

@media (min-width: 767px) {
  .fc-daygrid-day-events {
    .calendar__event-title {
      display: inline-block;
      white-space: pre-wrap;
      padding-right: 6px;
      padding-left: 4px;
    }
  }
}

.code-input {
  font-size: 1.5rem;
  width: 55px;
  border-radius: 10px;
  border: 1px solid #666;
  outline: 0;
  transition: 0.3s;
}

.code-input:focus {
  border: 1px solid $primary;
  box-shadow: 0 3px 10px 0rgba (34, 41, 47, 0.1);
  transition: 0.3s;
}

// ** User dropdown
.nav .dropdown-menu.dropdown-menu-end {
  @media (max-width: 767px) {
    width: 50% !important;
  }
}

// user profile tabs
.user-profile__tabs {
  .nav-item {
    .nav-link {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom-color: #fbf1e1;
    }
    .nav-link.active {
      color: $primary;
      border-bottom-color: $primary;
    }
  }
}

@media (max-width: 768px) {
  .header-navbar .navbar-container .dropdown.show .dropdown-menu {
    left: unset !important;
    right: 0 !important;
  }
}

// auth-wrapper
.auth-wrapper {
  background-color: white !important;
}

.admin-login_page.auth-wrapper {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.admin-login_page.auth-wrapper.auth-basic .auth-inner {
  z-index: 9999 !important;
}

@media (max-width: 767px) {
  .client-login_page.auth-wrapper.auth-v2 .auth-inner {
    height: unset !important;
  }
}

body.modal-open {
  overflow: hidden;
}

@media (max-width: 768px) {
  .fc-button-group {
    flex-flow: wrap;
    gap: 8px;
    button {
      border-radius: 5px !important;
    }
  }
}

// notification templates
.notification-templates.app-content {
  padding: 0 !important;
}

.notification-templates .scrollbar-container.email-user-list {
  background-color: white !important;
}

.email-application .content-area-wrapper .email-user-list {
  height: 100% !important;
}

.email-application .content-area-wrapper .email-user-list .email-media-list {
  padding-bottom: 3rem !important;
}

.notification-templates .mail-items span {
  white-space: pre-line;
}

// Earnings
.earnings .apexcharts-legend {
  display: none;
}

.earnings .earnings_select-container {
  width: 100%;

  .earnings_year-select,
  .earnings_property-select {
    width: 100%;
  }

  @media (min-width: 768px) {
    width: 75%;

    .earnings_year-select,
    .earnings_property-select {
      width: 25%;
    }
  }
}

// Business Date Filter
.business-date-filter {
  .business-date-filter_tow-dates {
    div.DateInput {
      width: 40%;
    }

    input.DateInput_input {
      font-weight: bold;
      font-size: 0.8rem;
      padding: 0.5rem 0.75rem 0.5rem;
    }

    input.DateInput_input.DateInput_input_1 {
      padding-right: 0 !important;
    }
  }
}

// ** Add Tenant Date Picker
.add-tenant_date-range {
  input.DateInput_input {
    font-size: 1rem;
    padding: 0.5rem 1rem;
  }
}

// ** Custom Check
.custom-options-checkable .custom-option-item {
  background-color: $body-bg;
}

.custom-options-checkable .custom-option-item {
  color: $gray-400;
}

.custom-options-checkable .custom-option-item .custom-option-item-title {
  color: $gray-400;
}

// ** React tow dates
button.DayPickerKeyboardShortcuts_buttonReset::before {
  display: none;
}

.DayPickerNavigation_button__horizontalDefault {
  top: 22px !important;
}

.CalendarMonthGrid_month__horizontal:nth-child(2n) {
  .CalendarMonth_caption {
    .calendar-options {
      justify-content: end !important;
      .calendar-years-select {
        margin-right: 0rem !important;
        margin-left: 0.25rem !important;
      }
    }
  }
}
.CalendarMonthGrid_month__horizontal:nth-child(n) {
  .CalendarMonth_caption {
    .calendar-months-select {
      margin-right: 0.25rem !important;
    }
  }
}

.date-range-picker__popover {
  .popover {
    max-width: 100% !important;
  }
}
.btn:disabled,
.btn.disabled {
  cursor: not-allowed !important;
  pointer-events: visible;
}

.popover-arrow:after {
  border-bottom-color: #f7f6f6 !important;
}
.dark-layout .popover-arrow:after {
  border-bottom-color: #283046 !important;
}

.dark-layout #dateRangeBtn {
  border: 1px solid #404656 !important;
}
#dateRangeBtn {
  border: 1px solid #ddd9ea;
}

.flatpickr-calendar.inline {
  margin-top: 10px;
}

@media (max-width: 992px) {
  .bs-stepper.vertical .bs-stepper-header {
    border-right: 0 !important;
  }
}

.kb-search-content {
  img {
    min-height: 387px;
  }
}

.grid-thumbnail {
  width: 96px;
  height: auto;
  margin-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
}

.property-card {
  transition: 0.2s;
  background: white;
  box-shadow: none;
  border-radius: 12px;

  &:hover {
    cursor: pointer;
    transform: translateY(-6px);
    transition: 0.2s;
  }

  .card-body {
    padding: 1.5rem;
  }

  .property-card__property-image {
    height: 350px;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
  }
}

.custom-swiper-navigation {
  // ** Swiper
  .swiper-button-prev,
  .swiper-button-next {
    width: 30px !important;
    height: 30px !important;
    background-color: white;
    border-radius: 50%;
    opacity: 0 !important;
  }

  .swiper-button-prev::after,
  .swiper-button-next::after {
    font-size: 12px !important;
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1 !important;
    }
  }
}

.images-collection__wrapper {
  position: relative;
  cursor: pointer;
  .btn {
    position: absolute;
    bottom: 20px;
    right: 40px;
    width: 150px;
    padding: 10px;
    background-color: white !important;
    color: #000 !important;
    opacity: 0;
    transition: 0.3s;
  }

  .bg-gradient-overlay {
    opacity: 0;
    top: 50%;
    right: 50%;
    left: 50%;
    width: 98%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    transition: 0.3s;
  }

  &:hover {
    .btn {
      opacity: 1;
      transition: 0.3s;
    }

    .bg-gradient-overlay {
      opacity: 0.7;
      transition: 0.3s;
    }
  }
}
.images-collection__image-container {
  width: 100%;
  height: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

.images-collection__tow-images-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .images-collection__image-container {
    width: 100%;
    height: 140px !important;
  }
}

.property-page__details-column {
  position: sticky;
  top: 25%;
}

.property-page-loader {
  margin-top: 150px;
}

.guest__popover {
  .popover {
    min-width: 419px;
  }
}

.guests-counter-number {
  width: 25px;
  display: inline-block;
  text-align: center;
}

.manage-attachment-col {
  display: flex;

  .attachment-thumbnail {
    width: 96px;
    height: auto;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: cover;
    }
    flex: 1;
  }

  .attachment-details {
    flex: 3;
  }
}

.card,
.dropdown-menu {
  background-color: white;
}

.property-info {
  svg,
  img {
    width: 50px;
  }
}

// ** For the property images gallery
.lg-backdrop {
  z-index: 1060 !important ;
}
.lg-outer {
  z-index: 1070 !important;
}

// ** Calendar
.check-in-out-calendar {
  @media (max-width: 768px) {
    div.fc-button-group:first-of-type {
      width: 100% !important;
    }
  }
  @media (max-width: 768px) {
    .fc-prevYear-button,
    .fc-nextYear-button {
      width: 25px !important;
      display: flex !important;
      justify-content: center !important;
    }
  }
  button.fc-nextYear-button.fc-button {
    background-color: transparent;
    margin-left: 0.3rem;

    span.fc-icon.fc-icon-chevrons-right {
      color: #005773;
    }
  }
}

// date picker
.flatpickr-disabled {
  background-color: rgb(234 84 85 / 65%) !important;
}

.priority-container {
  max-height: 400px;
  overflow-y: scroll;
}

.priority-container::-webkit-scrollbar {
  display: none;
}

.bed-icon {
  svg {
    width: 18px !important;
  }
}

.blueprint {
  .dropzone {
    height: 500px;
  }
}

.navigation {
  li {
    a {
      i,
      svg {
        height: 20px;
        width: 20px;
        font-size: 11px;
        margin-right: 1rem;
      }
    }
  }
}

// ** Filter bar
.property_filter-bar {
  .bar {
    height: 55px;
    border-radius: 100vw;
    font-size: 0.6rem;

    .wrapper {
      border-radius: inherit;
      padding: 0.8rem 1.5rem;
      transition: background 0.3s ease;
    }

    .wrapper:hover {
      background: #f0f0f0;
    }

    .active {
      background: #f0f0f0;
    }

    .location {
      width: 34%;
    }

    .value {
      font-size: 0.8rem;
      color: #b8c2cc;
    }

    .check-in,
    .check-out,
    .guests {
      width: 22%;
    }

    .guests .search {
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background: #005773;
      color: white;
      font-size: 0.8rem;
      padding: 0.7rem;
    }

    > .wrapper {
      position: relative;
    }

    > .wrapper::before {
      position: absolute;
      content: "";
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 55%;
      background: #e6e6e6;
    }

    > .wrapper:nth-of-type(1)::before {
      background: transparent;
    }

    > .wrapper:hover::before {
      background: transparent;
    }
  }

  p {
    font-weight: bold;
    font-size: 1rem;
  }
}

.property-location-filter {
  .location-col-light:hover {
    background: #f0f0f0;
  }

  .location-col-dark:hover {
    background: #151d32;
  }
}

.property-search-input {
  background: none;
  border: none;
  padding: 0.2rem 0 0 0;
  font-size: 0.8rem;
}

.property-search-input:focus {
  outline: none;
}

.property-search-input::placeholder {
  color: #b8c2cc;
}

// ** Color in dark mode
.theme-dark-text-color {
  color: #d0d2d6 !important;
}

// ** Chart
.apex-donut-chart {
  text {
    fill: #d0d2d6 !important;
  }
}

.table-dark-hover {
  --bs-table-hover-bg: #151d32;
}
