// App Email
// ========================================================================

@import '../../base/pages/app-email.scss';

// For editor height
.email-application {
  #message-editor .rdw-editor-main {
    min-height: 17.9rem;
  }
}

html[dir='rtl'] {
  .email-application {
    .email-app-details .email-detail-header {
      .email-header-left .go-back svg,
      .email-header-right .email-prev svg,
      .email-header-right .email-next svg {
        transform: rotate(180deg);
      }
    }

    .toggle-cc.me-1 {
      margin-right: 1rem;
      margin-left: 0 !important;
    }
  }
}
