//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

$primary: #005773 !default;
$secondary: #7ab54f !default;

$gradient-overlay: linear-gradient(
  to bottom,
  rgba(#161c2d, 0.5) 0%,
  rgba(#161c2d, 0.6) 25%,
  rgba(#161c2d, 0.7) 50%,
  rgba(#161c2d, 0.8) 100%
);
$enable-negative-margins: true;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);

$negative-margin-top: (
  responsive: true,
  property: margin-top,
  class: mt,
  values: $negative-spacers,
);

$negative-margin-bottom: (
  responsive: true,
  property: margin-bottom,
  class: mb,
  values: $negative-spacers,
);
